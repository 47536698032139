import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAlert.figmaUrl.android} codeUrl={checklists.componentAlert.codeUrl.android} checklists={checklists.componentAlert.checklists} mdxType="ChecklistResourcesSection" />
    <p>{`The Alert component is utilized to present a temporary surface that provides a list of options. It displays a brief message or notification to the user within an existing activity.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "alt": "Bottom Sheet Legion React Native",
            "height": 600,
            "resize-mode": "contain",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/react-native/react-native-alert-variant-success-screen-1.png",
            "width": "auto"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Alert } from "@legion-crossplatform/ui";

const Alert = () => {
  return (
    <Alert
      title="This is Alert"
      desc="Lorem ipsum dolor, sit amet consectetur 
      adipisicing elit. Iure asperiores sunt 
      repellat dolores quibusdam."
    />
  );
};
`}</code></pre>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "alt": "Bottom Sheet Legion React Native",
            "height": 600,
            "resize-mode": "contain",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/react-native/react-native-alert-variant-error-screen-1.png",
            "width": "auto"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`There are 4 variants available: `}<inlineCode parentName="p">{`Success`}</inlineCode>{`, `}<inlineCode parentName="p">{`Info`}</inlineCode>{`, `}<inlineCode parentName="p">{`Warning`}</inlineCode>{`, `}<inlineCode parentName="p">{`Error`}</inlineCode>{`. Following are example on how to use each variants:`}</p>
    <h3>{`Success`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Alert
  title="This is a Success Alert"
  desc="Lorem ipsum dolor, sit amet consectetur 
    adipisicing elit. Iure asperiores sunt 
    repellat dolores quibusdam."
  actionText="Call to Action"
  variant="success"
/>
`}</code></pre>
    <h3>{`Info`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Alert
  title="This is an Info Alert"
  desc="Lorem ipsum dolor, sit amet consectetur 
    adipisicing elit. Iure asperiores sunt 
    repellat dolores quibusdam."
  actionText="Call to Action"
  variant="info"
/>
`}</code></pre>
    <h3>{`Warning`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Alert
  title="This is a Warning Alert"
  desc="Lorem ipsum dolor, sit amet consectetur 
    adipisicing elit. Iure asperiores sunt 
    repellat dolores quibusdam."
  actionText="Call to Action"
  variant="warning"
/>
`}</code></pre>
    <h3>{`Error`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Alert
  title="This is an Error Alert"
  desc="Lorem ipsum dolor, sit amet consectetur 
    adipisicing elit. Iure asperiores sunt 
    repellat dolores quibusdam."
  actionText="Call to Action"
  variant="error"
/>
`}</code></pre>
    <div className="divi" />
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`String`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set text for action button in alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the children of Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`closable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To make alert closable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`desc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`String`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the description of Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`String`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the title of Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`success`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`info`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`warning`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the variant of Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the width of Alert`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      